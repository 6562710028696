/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function () {
				// JavaScript to be fired on all pages

				$('a[href^="#"]').click(function () {
					var $dom = $('html, body');
					$dom.animate({
						scrollTop: $($.attr(this, 'href')).offset().top
					}, 1000);

					return false;
				});

				$('.js-scrollTo, .liste-ancres a').on('click', function () { //smooth scroll pour les liens dans la page
					var page = $(this).attr('href');
					var speed = 750;
					//$('html, body').animate( { scrollTop: $(page).offset().top - $('header > .navbar').height() }, speed );
					$('html, body').animate({scrollTop: $(page).offset().top}, speed);
					return false;
				});

				$('.dropdown-toggle').click(function () {
					window.location.assign($(this).attr('href'));
				});

				$(".client-box").click(function () {
					var urlPage = $(this).find("a").attr("href");
					if ($(this).find("a").attr('data-video') === '1') {
						var title = $(this).find('a').attr('data-title');
						var iframe = '<iframe src="'+urlPage+'" width="100%" height="360" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';
						$('.modal-title').html(title);
						$('.modal-body').html(iframe);
						$('#clientModal').modal('show');
					}
					else {
						window.location = urlPage;
					}
				});

				$('.info-devis-button').click(function () {
					$('.info-devis-contact').css({
						'transform': 'translateY(0)',
						'opacity' : '1',
						'visibility': 'visible'
					});


					$('.info-devis').css('height', '600px');
				});

				$('#clientModal').on('hidden.bs.modal', function (e) {
					$('#clientModal iframe').attr('src', null);
				});

				//JS pour  les boutons file input
				$(document).on('change', ':file', function () {
					var input = $(this),
						numFiles = input.get(0).files ? input.get(0).files.length : 1,
						label = input.val().replace(/\\/g, '/').replace(/.*\//, '');
					input.trigger('fileselect', [numFiles, label]);
				});

				// We can watch for our custom `fileselect` event like this
				$(document).ready(function () {
					$(':file').on('fileselect', function (event, numFiles, label) {

						var input = $(this).parents('.input-group').find(':text'),
							log = numFiles > 1 ? numFiles + ' files selected' : label;

						if (input.length) {
							input.val(log);
						} else {
							if (log) {
								alert(log);
							}
						}

					});
				});

			},
			finalize: function () {
				// JavaScript to be fired on all pages, after page specific JS is fired
				$('.owl-temoignages').owlCarousel({
					loop: true,
					items: 1,
					dotsEach: 1,
					autoplay: 1,
					autoplayTimeout: 2500,
					smartSpeed: 1000,
					autoplayHoverPause: true
				});
				$('.owl-clients').owlCarousel({
					loop: true,
					//items: 6,
					dotsEach: 1,
					autoplay: 1,
					autoplayTimeout: 2500,
					smartSpeed: 1000,
					autoplayHoverPause: false,
					autoWidth: true,
					margin: 50,

					responsive: {
						0: {
							items: 1
						},
						480: {
							items: 3
						},
						768: {
							items: 6
						}
					}
				});
				$('.owl-galerie').owlCarousel({
					loop: true,
					items: 1,
					//dotsEach: 1,
					autoplay: 1,
					autoplayTimeout: 2500,
					smartSpeed: 1000,
					autoplayHoverPause: false,
					dots: false,
					//autoWidth: true,
				});

				// $('.match-height-row').matchHeight({byRow: true});

				// $('.match-height').matchHeight();
				// $('.match-height-2').matchHeight();

				function sizing () {
					var container = $('.container-galerie');
					var img = container.find('img');

					img.each(function () {
						var newMargin = (container.width() - $(this).width()) / 2;

						if ($(this).height() > container.height()) {
							$(this).css({'max-height': container.outerHeight - 20});
						}

						$(this).css({'margin-left': newMargin});

						if (container.height() > $(this).outerHeight()) {
							var margin = (container.outerHeight() - $(this).height()) / 2;
							$(this).css({'margin-top': margin});
						}
					});
				}

				$(window).load(sizing);
				$(window).resize(sizing);


				// init controller
				//var controller = new ScrollMagic({globalSceneOptions: {triggerHook: "onEnter", duration: $(window).height()*2}});
				/*	var controller = new ScrollMagic.Controller({globalSceneOptions: {triggerHook: "onEnter", duration: $(window).height()*2}});

					new ScrollMagic.Scene({triggerElement: ".parallax-container"})
									.setTween(TweenMax.from(".parallax-bg", 1, {top: "-120%", ease: Linear.easeNone}))
									.addTo(controller);
				*/

			}
		},
		// Home page
		'home': {
			init: function () {
				// JavaScript to be fired on the home page
			},
			finalize: function () {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'contact': {
			init: function () {
				// JavaScript to be fired on the about us page

				var carte = new Maplace({

					map_options: {
						scrollwheel: false,
						zoomControl: true,
						mapTypeControl: false,
						scaleControl: false,
						streetViewControl: false,
						rotateControl: false,
						fullscreenControl: false
					},

					styles: {
						'Greyscale': [{
							featureType: 'all',
							stylers: [
								{saturation: -100},
								{gamma: 0.50}
							]
						}]
					},


					//locations: adresses,

					//start: 1,
					controls_on_map: false
				});

				if (typeof(adresses) !== 'undefined') {
					carte.Load({locations: adresses});
				}
			}
		},
		'nos_references': {
			init: function () {
			},

			finalize: function () {
				$('.listing-partner').appear(function () {
					$('img', this).each(function (i, el) {
						setTimeout(function () {
							$(el).removeClass('invisible').addClass('animated zoomIn');
						}, 500 + (i * (Math.floor(Math.random() * Math.floor(10) * 30))));
					});
				});
			}
		},
		'agence_communication_metz_luxembourg_strasbourg': {
			init: function () {
			},

			finalize: function () {
				$('.date').appear(function () {
					$('p', this).each(function (i, el) {
						setTimeout(function () {
							$(el).removeClass('invisible').addClass('animated fadeInLeft');
						}, 500 + (i * 1000));
					});
				});
			}
		},

		'voeux_2019': {
			init: function () {

			},
			finalize: function () {
				var wpcf7 = document.querySelector('.wpcf7');
				wpcf7.addEventListener('wpcf7mailsent', function (e) {
					setTimeout(function () {
						window.location = 'https://www.groupeget.com/jeu-2019/';
					}, 1000);
				}, false);
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
